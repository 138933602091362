<template>
  <div class="about-container">
    <div class="about-container1">
      <h1 class="about-text">About us</h1>
      <div class="about-hero">
        <span class="about-text01">
          <span class="about-text02">Dodo (&quot;Dodo Web3 LLP&quot;)</span>
          <span class="about-text03">
            is a software development company that specializes in building
            innovative software products. Our founder, Ashfaq Mehmood, is an
            awardee of the Best Innovation in Science and Technology Award 2022 in
            the state of Jammu and Kashmir, India, and has been recognized for his
            contributions to the field of technology.
          </span>
          <br class="about-text04" />
          <br class="about-text05" />
          <span class="about-text06">
            At Dodo, we are passionate about developing cutting-edge software
            products that help businesses and individuals achieve their goals. Our
            team of experienced developers is well-versed in developing apps for
            both Android and iOS platforms, as well as creating responsive and
            user-friendly websites that cater to the needs of our clients.
          </span>
          <br class="about-text07" />
          <br class="about-text08" />
          <span class="about-text09">
            Our business address is located in Rajouri, Jammu and Kashmir, India - 185131, and we are incorporated in India with LLP Identification Number - ABB-7578.
          </span>
          <br class="about-text10" />
          <br class="about-text11" />
          <span class="about-text12">
            We believe that innovation is the key to success in today&apos;s
            fast-paced digital world, and we work tirelessly to stay up-to-date
            with the latest technologies and trends in software development.
            Whether you need a custom mobile app, a sophisticated web application,
            or a software product that meets your unique requirements, we are here
            to help.
          </span>
          <br class="about-text13" />
          <br class="about-text14" />
          <span class="about-text15">
            At Dodo, we are committed to delivering high-quality software products
            that meet the needs of our clients. We work closely with our clients
            to understand their requirements and provide them with customized
            solutions that are tailored to their specific needs. Our team of
            developers is skilled in a wide range of programming languages and
            development frameworks, and we use the latest tools and technologies
            to build robust and scalable software products.
          </span>
          <br class="about-text16" />
          <br class="about-text17" />
          <span class="about-text18">
            In addition to our development services, we also offer ongoing
            maintenance and support for our software products. We understand that
            technology is constantly evolving, and we work to ensure that our
            products remain up-to-date and continue to meet the needs of our
            clients.
          </span>
          <br />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo: {
    title: 'About us',
    meta: [
      {
        property: 'og:title',
        content: 'About us',
      },
    ],
  },
}
</script>

<style scoped>
.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.about-text {
  color: rgb(255, 255, 255);
  margin-top: 300px;
  margin-left: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.about-hero {
  width: auto;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.about-text01 {
  text-align: justify;
}
.about-text02 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 700;
  line-height: 1.5;
}
.about-text03 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text04 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text05 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text06 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text07 {
  line-height: 1.5;
}
.about-text08 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text09 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text10 {
  line-height: 1.5;
}
.about-text11 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text12 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text13 {
  line-height: 1.5;
}
.about-text14 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text15 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text16 {
  line-height: 1.5;
}
.about-text17 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.about-text18 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
@media(max-width: 991px) {
  .about-hero {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .about-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .about-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
</style>
