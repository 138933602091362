import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import About from './views/about'
import Home from './views/home'
import PrivacyPolicy from './views/privacy-policy'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'About',
      path: '/about',
      component: About,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
  name: 'Donation',
  path: '/donate',
  component: () => import('./components/donation.vue'), // Adjust the path to your component
},
      {
      name: 'Privacy-Policy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      path: '/GETit/privacy-policy.html',
      beforeEnter() {location.href = 'https://gododo.in/privacy-policy';}
    },
    {
      path: '/instagram',
      beforeEnter() {location.href = 'https://www.instagram.com/gododo.in';}
    }
  ],
})

