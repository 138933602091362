<template>
  <div class="home-container">
    <div data-modal="practices" class="home-modal">
      <div class="home-practices">
        <div class="home-heading">
          <span class="home-header">Our practices</span>
          <svg viewBox="0 0 1024 1024" data-close="practices" class="home-close">
            <path
              d="M225.835 286.165l225.835 225.835-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l225.835-225.835 225.835 225.835c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-225.835-225.835 225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-225.835 225.835-225.835-225.835c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"
            ></path>
          </svg>
        </div>
        <div class="home-grid">
          <div class="home-section">
            <div class="home-heading1">
              <span class="home-header1">Cardiology</span>
              <span class="home-caption">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text">Read more</span>
              <img alt="image" src="/Icons/arrow-2.svg" class="home-image" />
            </div>
          </div>
          <div class="home-section1">
            <div class="home-heading2">
              <span class="home-header2">Orthopedics</span>
              <span class="home-caption1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text01">Read more</span>
              <img alt="image" src="/Icons/arrow-2.svg" class="home-image1" />
            </div>
          </div>
          <div class="home-section2">
            <div class="home-heading3">
              <span class="home-header3">Ophtalmology</span>
              <span class="home-caption2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text02">Read more</span>
              <img alt="image" src="/Icons/arrow-2.svg" class="home-image2" />
            </div>
          </div>
          <div class="home-section3">
            <div class="home-heading4">
              <span class="home-header4">Pediatrics</span>
              <span class="home-caption3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text03">Read more</span>
              <img alt="image" src="/Icons/arrow-2.svg" class="home-image3" />
            </div>
          </div>
          <div class="home-section4">
            <div class="home-heading5">
              <span class="home-header5">Nutrition</span>
              <span class="home-caption4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text04">Read more</span>
              <img alt="image" src="/Icons/arrow-2.svg" class="home-image4" />
            </div>
          </div>
          <div class="home-section5">
            <div class="home-heading6">
              <span class="home-header6">General</span>
              <span class="home-caption5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </span>
            </div>
            <div class="read-more">
              <span class="home-text05">Read more</span>
              <img alt="image" src="/Icons/arrow-2.svg" class="home-image5" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="home-hero">
      <header data-thq="thq-navbar" class="home-navbar">
        <div class="home-left">
          <img
            alt="image"
            src="https://i.ibb.co/YDBFhbh/Final-Logo-Icon.png"
            class="home-logo"
          />
        </div>
        <div data-thq="thq-navbar-btn-group" class="home-right">
          <button class="home-phone button">
            <img alt="image" src="/Icons/phone.svg" class="home-image6" />
            <span class="home-text06">+1 970 822 7409</span>
          </button>
        </div>
        <div data-thq="thq-burger-menu" class="home-burger-menu"></div>
        <div data-thq="thq-mobile-menu" class="home-mobile-menu"></div>
      </header>
      <div class="home-main">
        <div class="home-content">
          <div class="home-heading7">
            <h1 class="home-header7">
              <span class="home-text07">We build software that just works</span>
              <br />
              <br />
            </h1>
          </div>
        </div>
      </div>
      <div id="features" class="home-features">
        <div class="home-content1">
          <a
            href="https://play.google.com/store/apps/details?id=go.dodo.GETit&hl=en_IN"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link"
          >
            <app-features
              title="Projects"
              Description="View our portfolio and see some of our recent projects."
              class="home-component"
            ></app-features>
          </a>
          <router-link to="/about" class="home-navlink">
            <app-features
              title="About"
              Description="Learn more about our team and our company."
              class="home-component1"
            ></app-features>
          </router-link>
          <a
            href="https://us21.list-manage.com/contact-form?u=4050ef5fd55b0c879637beff0&amp;form_id=ecfc7b4eae180dbf58d600debb80de6c"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link1"
          >
            <app-features
              title="Contact"
              Description="Get in touch with us to discuss your project and get a free quote."
              class="home-component2"
            ></app-features>
          </a>
        </div>
      </div>
      <div class="home-background"></div>
    </section>
    <div class="home-footer">
      <div class="home-left1">
        <div class="home-brand">
          <img
            alt="image"
            src="https://i.ibb.co/vqX7LHf/Icon-White-Transparent-BG.png"
            class="home-image7"
          />
        </div>
        <div class="home-socials"></div>
        <div class="home-legal">
          <span class="home-copyright">
            © 2024 Dodo Web3 LLP. All Rights Reserved.
          </span>
          <a
            href="https://www.gododo.in/privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
            class="home-copyright1 legal-link"
          >
            Privacy Policy
          </a>
          <a
            href="https://linkedin.com/company/dodoweb3llp"
            target="_blank"
            rel="noreferrer noopener"
            class="home-copyright2 legal-link"
          >
            Linkedin
          </a>
        </div>
      </div>
      <div class="home-right1">
        <div class="home-list">
          <span class="home-header8">Dodo Web3 LLP</span>
          <div class="home-links">
            <span class="home-link2">
              <span class="home-text10">Rajouri</span>
              <br />
              <span>Jammu and Kashmir, India - 185131</span>
              <br />
            </span>
            <a href="mailto:hello@gododo.in?subject=Query" class="home-link3">
              hello@gododo.in
            </a>
            <a href="tel:(970) 822 - 7409" class="home-link4">
              <span class="home-text14">+1 970 822 7409</span>
              <br />
              <br />
              <span>LLPIN: ABB-7578</span></br><span>GSTIN: 01AAUFD0783H1ZF</span>
              <br />
            </a>
          </div>
        </div>
      </div>
      <div class="home-legal1">
        <div class="home-row">
          <a
            href="https://gododo.in/GETit/privacy-policy.html"
            target="_blank"
            rel="noreferrer noopener"
            class="home-copyright3 legal-link"
          >
            Privacy Policy
          </a>
          <a
            href="https://linkedin.com/company/dodoweb3llp"
            target="_blank"
            rel="noreferrer noopener"
            class="home-copyright4 legal-link"
          >
            Linkedin
          </a>
        </div>
        <span class="home-copyright5">
          © 2023 Dodo Global Limited. All Rights Reserved.
        </span>
      </div>
    </div>
    <div>
      <DangerousHTML
        html="<script>
  const modalOpen = document.querySelectorAll('[data-open]');
  const modalClose = document.querySelectorAll('[data-close]');
  
  modalOpen.forEach(button => {
      button.addEventListener('click', event => {
          const modal = document.querySelector(`[data-modal='${event.target.dataset.open}']`);
          modal.style.display = 'flex';
      });
  });
  
  modalClose.forEach(button => {
      button.addEventListener('click', event => {
          const modal = document.querySelector(`[data-modal='${event.target.dataset.close}']`);
          modal.style.display = 'none';
      });
  });
  </script>
  "
      ></DangerousHTML>
    </div>
    <div>
      <DangerousHTML
        html="<script>
  const dataLetters = document.querySelectorAll('[data-letter]');
  let activeLetters = [];
  const maxResults = 6;
  
  dataLetters.forEach(letter => {
    letter.addEventListener('click', function() {
      if (this.classList.contains('letter-active')) {
        this.classList.remove('letter-active');
        activeLetters = activeLetters.filter(a => a !== this.dataset.letter);
      } else {
        this.classList.add('letter-active');
        activeLetters.push(this.dataset.letter);
      }
      if (activeLetters.length == 0) {
        document.querySelector('[data-teleport='results']').style.display = 'none';
        return;
      }
      showResults();
    });
  });
  
  const showResults = () => {
    fetch('https://raw.githubusercontent.com/Shivanshu-Gupta/web-scrapers/master/medical_ner/medicinenet-diseases.json')
      .then(response => response.json())
      .then(data => {
        const filteredData = data.filter(item => {
          const firstLetter = item.disease.charAt(0).toLowerCase();
          if (activeLetters.includes(firstLetter)) {
            return true;
          }
          return false;
        });
  
        document.querySelector('[data-teleport='results']').style.display = 'flex';
        const resultsContainer = document.querySelector('[data-results='letters']');
        resultsContainer.innerHTML = '';
  
        let counter = 0;
        const diseaseGroups = {};
        const totalActiveLetters = activeLetters.length;
  
        filteredData.forEach(disease => {
          const firstLetter = disease.disease[0].toLowerCase();
          if (diseaseGroups[firstLetter]) {
            diseaseGroups[firstLetter].push(disease);
          } else {
            diseaseGroups[firstLetter] = [disease];
          }
        });
  
        Object.keys(diseaseGroups).sort().forEach((firstLetter, index) => {
          const diseasesForThisLetter = diseaseGroups[firstLetter];
          const diseasesToShow = diseasesForThisLetter.slice(0, Math.ceil(maxResults / totalActiveLetters));
  
          diseasesToShow.forEach(disease => {
            const resultContainer = document.createElement('div');
            resultContainer.classList.add('search-result');
            resultContainer.classList.add('invisible');
            resultContainer.style.animationDelay = `${counter * 0.25}s`;
  
            const resultText = document.createElement('span');
            resultText.classList.add('result-text');
            resultText.textContent = disease.disease;
  
            resultContainer.appendChild(resultText);
            resultsContainer.appendChild(resultContainer);
            counter++;
  
            if (counter === maxResults) {
              const moreContainer = document.createElement('div');
              moreContainer.classList.add('search-result');
              moreContainer.classList.add('more-results');
  
              const moreText = document.createElement('span');
              moreText.classList.add('result-text');
              moreText.textContent = 'More';
  
              moreContainer.appendChild(moreText);
              resultsContainer.appendChild(moreContainer);
              addedMoreContainer = true;
              return;
            }
          });
        });
      });
  };
  </script>
  "
      ></DangerousHTML>
    </div>
    <div>
      <DangerousHTML
        html="<script>
  function scroll(direction) {
    const doctorsDiv = document.querySelector('[data-teleport='doctors']');
    const scrollAmount = 300;
    if (direction === 'previous') {
      doctorsDiv.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
    } else if (direction === 'next') {
      doctorsDiv.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  }
  
  const buttons = document.querySelectorAll('[data-doctors]');
  buttons.forEach(button => {
    button.addEventListener('click', () => {
      const direction = button.dataset.doctors;
      scroll(direction);
    });
  });
  </script>"
      ></DangerousHTML>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppFeatures from '../components/features'

export default {
  name: 'Home',
  components: {
    AppFeatures,
    DangerousHTML,
  },
  metaInfo: {
    title: 'Dodo',
    meta: [
      {
        property: 'og:title',
        content: 'Dodo',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
}
.home-modal {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 200;
  position: fixed;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  backdrop-filter: blur(4px);
  justify-content: center;
}
.home-practices {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 0px 0px 25px -2px rgba(66, 68, 90, 0.4);
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.home-heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}
.home-close:hover {
  opacity: 0.5;
}
.home-grid {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-section {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption {
  max-width: 385px;
  line-height: 24px;
}
.home-text {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image {
  width: 12px;
  object-fit: cover;
}
.home-section1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header2 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption1 {
  max-width: 385px;
  line-height: 24px;
}
.home-text01 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image1 {
  width: 12px;
  object-fit: cover;
}
.home-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header3 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption2 {
  max-width: 385px;
  line-height: 24px;
}
.home-text02 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image2 {
  width: 12px;
  object-fit: cover;
}
.home-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header4 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption3 {
  max-width: 385px;
  line-height: 24px;
}
.home-text03 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image3 {
  width: 12px;
  object-fit: cover;
}
.home-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header5 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption4 {
  max-width: 385px;
  line-height: 24px;
}
.home-text04 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image4 {
  width: 12px;
  object-fit: cover;
}
.home-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header6 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption5 {
  max-width: 385px;
  line-height: 24px;
}
.home-text05 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image5 {
  width: 12px;
  object-fit: cover;
}
.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-left {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-logo {
  width: 165px;
  object-fit: cover;
}
.home-right {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-phone {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
}
.home-image6 {
  width: 18px;
  object-fit: cover;
}
.home-text06 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-main {
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: stretch;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.home-heading7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header7 {
  width: 100%;
  font-size: 62px;
  max-width: 750px;
  font-style: normal;
  font-weight: 600;
  line-height: 74px;
}
.home-text07 {
  width: 100%;
  max-width: 750px;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: #2461FF;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-navlink {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-background {
  right: 0px;
  width: 37%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #D0F4FD;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #000000;
}
.home-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-brand {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image7 {
  width: 165px;
  object-fit: cover;
}
.home-socials {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: row;
}
.home-legal {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.home-copyright1 {
  text-decoration: none;
}
.home-copyright2 {
  text-decoration: none;
}
.home-right1 {
  gap: 140px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-list {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header8 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-links {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link2 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
}
.home-link2:hover {
  opacity: 0.5;
}
.home-text10 {
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
}
.home-text10:hover {
  opacity: 0.5;
}
.home-link3 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.home-link3:hover {
  opacity: 0.5;
}
.home-link4 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.home-link4:hover {
  opacity: 0.5;
}
.home-text14 {
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
}
.home-text14:hover {
  opacity: 0.5;
}
.home-legal1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-row {
  display: flex;
}
.home-copyright3 {
  text-decoration: none;
}
.home-copyright4 {
  text-decoration: none;
}
.home-copyright5 {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
@media(max-width: 991px) {
  .home-hero {
    flex: 1;
    height: 0px;
  }
  .home-navbar {
    z-index: 1000;
  }
  .home-right {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #2461FF;
  }
  .home-main {
    flex-direction: column;
  }
  .home-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-header7 {
    font-size: 62px;
    max-width: 75%;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
  }
  .home-text07 {
    max-width: 75%;
  }
  .home-features {
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content1 {
    flex-direction: column;
  }
  .home-background {
    left: 0px;
    width: 100%;
    bottom: 0px;
    height: 55%;
    position: absolute;
    justify-content: flex-end;
  }
  .home-footer {
    gap: 100px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-left1 {
    padding-right: 0px;
  }
  .home-socials {
    display: none;
  }
  .home-legal {
    display: none;
  }
  .home-right1 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-legal1 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-direction: column;
  }
  .home-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .home-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    display: flex;
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .home-header7 {
    font-size: 32px;
    max-width: 70%;
    line-height: 38px;
  }
  .home-text07 {
    font-size: 32px;
    max-width: 70%;
    line-height: 38px;
  }
  .home-content1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-image7 {
    width: 110px;
  }
}
@media(max-width: 479px) {
  .home-navbar {
    padding: var(--dl-space-space-unit);
  }
  .home-right {
    display: none;
    opacity: 1;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header7 {
    font-size: 62px;
    max-width: 80%;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
  }
  .home-text07 {
    font-size: 62px;
    max-width: 80%;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
  }
  .home-list {
    height: 169px;
  }
  .home-header8 {
    color: rgb(255, 255, 255);
  }
  .home-link2 {
    color: rgb(255, 255, 255);
  }
  .home-link3 {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  .home-link4 {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  .home-copyright5 {
    color: rgb(255, 255, 255);
  }
}
</style>
