<template>
  <div class="privacy-policy-container">
    <div class="privacy-policy-container1">
      <h1 class="privacy-policy-text">Privacy Policy</h1>
      <div class="privacy-policy-hero">
        <span class="privacy-policy-text001">
          <span class="privacy-policy-text002">
            When you use our services, you’re trusting us with your information.
            We understand this is a big responsibility and work hard to protect
            your information and put you in control.
          </span>
          <br class="privacy-policy-text003" />
          <br class="privacy-policy-text004" />
          <span class="privacy-policy-text005">
            This Privacy Policy is meant to help you understand what information
            we collect and why we collect it. If you choose to use our Service,
            then you agree to the collection and use of information in relation to
            this policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
          </span>
          <br class="privacy-policy-text006" />
          <br class="privacy-policy-text007" />
          <br class="privacy-policy-text008" />
          <span class="privacy-policy-text009">Permission and Access</span>
          <br />
          <br class="privacy-policy-text011" />
          <span class="privacy-policy-text012">
            Dodo Drop requires some permissions to function effectively and
            efficiently. Without some of these permissions enabled, you might be
            unable to perform certain operations with the software.
          </span>
          <br />
          <br class="privacy-policy-text014" />
          <span class="privacy-policy-text015">
            Dodo Drop requires the enablement of the following permissions:
          </span>
          <br class="privacy-policy-text016" />
          <br class="privacy-policy-text017" />
          <span class="privacy-policy-text018">     1) Camera</span>
          <br class="privacy-policy-text019" />
          <span class="privacy-policy-text020">     2) Location</span>
          <br class="privacy-policy-text021" />
          <span class="privacy-policy-text022">     3) Telephone</span>
          <br class="privacy-policy-text023" />
          <span class="privacy-policy-text024">     4) Storage</span>
          <br class="privacy-policy-text025" />
          <br class="privacy-policy-text026" />
          <span class="privacy-policy-text027">
            The main permission required to get Dodo Drop functioning is the
            storage permission, the rest permissions are sometimes required to
            perform some operations but they are optional and can be enabled and
            disabled anytime at will when not in use.
          </span>
          <br class="privacy-policy-text028" />
          <br class="privacy-policy-text029" />
          <span class="privacy-policy-text030">
            Dodo Drop has some other capabilities too:
          </span>
          <br class="privacy-policy-text031" />
          <br class="privacy-policy-text032" />
          <span class="privacy-policy-text033">a)  </span>
          <span class="privacy-policy-text034">View WiFi connections:</span>
          <span class="privacy-policy-text035">
            This capability allows the app to view information about WiFi
            networking such as                whether WiFi is enabled and the name
            of connected WiFi devices.
          </span>
          <br class="privacy-policy-text036" />
          <br class="privacy-policy-text037" />
          <span class="privacy-policy-text038">
            b)  Connect and disconnect from WiFi: This allows the app to connect
            and disconnect from WiFi access points           and to make changes
            to the device configuration for WiFi networks.
          </span>
          <br class="privacy-policy-text039" />
          <br class="privacy-policy-text040" />
          <span class="privacy-policy-text041">
            c)  Change network connectivity: This app as the capability to change
            the state of network connectivity.
          </span>
          <br class="privacy-policy-text042" />
          <br class="privacy-policy-text043" />
          <span class="privacy-policy-text044">
            d)  Have full network access: The app is capable of creating network
            sockets and can use customized network          protocols. This
            capability does not require the software to send data to the internet.
          </span>
          <br class="privacy-policy-text045" />
          <br class="privacy-policy-text046" />
          <span class="privacy-policy-text047">
            e)  View network connections: This is the capability of the app to
            view information about network connections             such as which
            networks exist and are connected.
          </span>
          <br class="privacy-policy-text048" />
          <br class="privacy-policy-text049" />
          <span class="privacy-policy-text050">
            f)   Control vibration: This app can control your device vibrator.
          </span>
          <br class="privacy-policy-text051" />
          <br class="privacy-policy-text052" />
          <span class="privacy-policy-text053">
            g) Prevent phone from sleeping: When running, the app has the
            capability to prevent your device from sleeping.
            <span v-html="rawn3id"></span>
          </span>
          <br class="privacy-policy-text054" />
          <br class="privacy-policy-text055" />
          <br class="privacy-policy-text056" />
          <span class="privacy-policy-text057">
            Information and Data Collection
          </span>
          <br class="privacy-policy-text058" />
          <br class="privacy-policy-text059" />
          <span class="privacy-policy-text060">
            We do not externally collect any information or data when the services
            of Dodo Drop are utilized. When performing some activities with the
            software, personal data is not collected by us because all the
            information is locally stored on your device. Information contained in
            a file might be shared between paired devices during an operation but
            not in any way linked to us.
          </span>
          <br class="privacy-policy-text061" />
          <br class="privacy-policy-text062" />
          <span class="privacy-policy-text063">
            However, Dodo Drop does use third party services that may collect
            information used to identify you including Google Play Services,
            Google AdMob, Google Analytics for Firebase, Firebase Crashlytics.
          </span>
          <br class="privacy-policy-text064" />
          <br class="privacy-policy-text065" />
          <br class="privacy-policy-text066" />
          <span class="privacy-policy-text067">Transfer Of Data</span>
          <br class="privacy-policy-text068" />
          <br class="privacy-policy-text069" />
          <span class="privacy-policy-text070">
            With these third-party libraries contained in the software, your
            information, including personal data, may be transferred to computers
            located outside of your state, province, country or other governmental
            jurisdiction where the data protection laws may differ than those from
            your jurisdiction. Your consent to this Privacy Policy followed by
            your submission of such information represents your agreement to that
            transfer.
          </span>
          <br class="privacy-policy-text071" />
          <br class="privacy-policy-text072" />
          <br class="privacy-policy-text073" />
          <span class="privacy-policy-text074">Links to other sites</span>
          <br class="privacy-policy-text075" />
          <br class="privacy-policy-text076" />
          <span class="privacy-policy-text077">
            Dodo Drop contains links to other services that are not directly
            operated by Dodo. If you click on a third party library webpage or
            license URL, you will be directed to that third party&apos;s site.
          </span>
          <br class="privacy-policy-text078" />
          <br class="privacy-policy-text079" />
          <span class="privacy-policy-text080">
            We have no control over and assumes no responsibility for the content,
            privacy policies or practices of any third party sites or services.
          </span>
          <br class="privacy-policy-text081" />
          <br class="privacy-policy-text082" />
          <br class="privacy-policy-text083" />
          <span class="privacy-policy-text084">
            Changes to this Privacy Policy
          </span>
          <br class="privacy-policy-text085" />
          <br class="privacy-policy-text086" />
          <span class="privacy-policy-text087">
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </span>
          <br class="privacy-policy-text088" />
          <br class="privacy-policy-text089" />
          <span class="privacy-policy-text090">
            This policy is effective as of 08-08-2023.
          </span>
          <br class="privacy-policy-text091" />
          <br class="privacy-policy-text092" />
          <br class="privacy-policy-text093" />
          <span class="privacy-policy-text094">Disclaimer</span>
          <br class="privacy-policy-text095" />
          <br class="privacy-policy-text096" />
          <span class="privacy-policy-text097">
            The operation of Dodo Drop is basically for file sharing and app
            transfer which is a daily activity. During this process, there&apos;s
            a high probability that personal data contained in some files might be
            shared with a paired client which might lead to security breaches and
            you may encounter loss and damages. We will not be held accountable
            for how you decide to use this software and for contents you decide to
            share with a paired party and will not be liable for any losses and/or
            damages in connection with the use of the software.
          </span>
          <br class="privacy-policy-text098" />
          <br class="privacy-policy-text099" />
          <br class="privacy-policy-text100" />
          <span class="privacy-policy-text101">Contact us</span>
          <br class="privacy-policy-text102" />
          <br class="privacy-policy-text103" />
          <span class="privacy-policy-text104">
            If you have any questions or suggestions about our Privacy Policy or
            the practices of this software, do not hesitate to contact us at
            support@gododo.in
          </span>
          <br />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      rawn3id: ' ',
    }
  },
  metaInfo: {
    title: 'Privacy-Policy - Medica template',
    meta: [
      {
        property: 'og:title',
        content: 'Privacy-Policy - Medica template',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-policy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.privacy-policy-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #000000;
}
.privacy-policy-text {
  color: rgb(255, 255, 255);
  margin-top: 300px;
  margin-left: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.privacy-policy-hero {
  width: auto;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.privacy-policy-text001 {
  text-align: justify;
}
.privacy-policy-text002 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8;
}
.privacy-policy-text003 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 600;
  line-height: 1.5;
}
.privacy-policy-text004 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.privacy-policy-text005 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text006 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.privacy-policy-text007 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.privacy-policy-text008 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.privacy-policy-text009 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.privacy-policy-text011 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.5;
}
.privacy-policy-text012 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text014 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text015 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text016 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text017 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text018 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text019 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text020 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text021 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text022 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text023 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text024 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text025 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text026 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text027 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text028 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text029 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text030 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text031 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text032 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text033 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text034 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8;
}
.privacy-policy-text035 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text036 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text037 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text038 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text039 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text040 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text041 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text042 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text043 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text044 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text045 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text046 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text047 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text048 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text049 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text050 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text051 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text052 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text053 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text054 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text055 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 600;
  line-height: 1.8;
}
.privacy-policy-text056 {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 600;
  line-height: 1.8;
}
.privacy-policy-text057 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text058 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text059 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text060 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text061 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text062 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text063 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text064 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text065 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text066 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text067 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text068 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text069 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text070 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text071 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text072 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text073 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text074 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text075 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text076 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text077 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text078 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text079 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text080 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text081 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text082 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text083 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text084 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text085 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text086 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text087 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text088 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text089 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text090 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text091 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text092 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text093 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text094 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text095 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text096 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text097 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text098 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text099 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text100 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text101 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8;
}
.privacy-policy-text102 {
  font-size: 18px;
  line-height: 1.8;
}
.privacy-policy-text103 {
  color: #1e1e1e;
  font-size: 18px;
  font-style: normal;
  font-family: Noto Sans;
  font-weight: 400;
  line-height: 1.8;
}
.privacy-policy-text104 {
  font-size: 18px;
  line-height: 1.8;
}
@media(max-width: 991px) {
  .privacy-policy-hero {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .privacy-policy-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .privacy-policy-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
</style>
